<template>
  <router-view />
</template>

<script>
import storeMixin, {
  unRegisterMixin,
} from '@quisit/mipe-utils/mixins/storeGenerator';
const STORE_MODULE_NAME = 'HistoryLocationModule';

export default {
  name: 'WrapperHistoryLocation',
  mixins: [unRegisterMixin, storeMixin],
  data: () => ({
    moduleName: STORE_MODULE_NAME,
  }),
};
</script>
